import { XIcon } from "@heroicons/react/solid";
import classNames from "classnames";

export interface TodoItem {
  contents: string;
  uuid: string;
  checked: boolean;
}

const ItemRow = ({
  item,
  onDelete,
  onCheck,
  canUpdate,
  canDelete,
}: {
  item: TodoItem;
  onDelete: () => void;
  onCheck: (checked: boolean) => void;
  canUpdate: boolean;
  canDelete: boolean;
}) => (
  <div className="group flex flex-row items-center">
    <input
      type="checkbox"
      className="mr-2 group-hover:ring-2 ring-blue-600 w-4 h-4"
      checked={item.checked}
      onChange={(e) => onCheck(e.target.checked)}
      disabled={!canUpdate}
    />
    <p className={classNames("flex-1", { "line-through": item.checked })}>
      {item.contents}
    </p>
    <button
      className={classNames(
        "opacity-0 group-hover:opacity-100 rounded-full p-0.5 hover:bg-gray-200 focus:outline-none",
        { hidden: !canDelete }
      )}
      onClick={onDelete}
    >
      <XIcon className="w-4 h-4 text-gray-600" />
    </button>
  </div>
);

export default ItemRow;
