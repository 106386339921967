import { useReducer, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import ItemRow, { TodoItem } from "./ItemRow";
import EntryRow from "./EntryRow";

interface State {
  todoList: string[];
  items: Record<string, TodoItem>;
}

type Action =
  | { type: "CREATE"; contents: string }
  | { type: "DELETE"; uuid: string }
  | { type: "CHECK"; uuid: string; checked: boolean };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "CREATE": {
      const item: TodoItem = {
        contents: action.contents,
        uuid: uuidv4(),
        checked: false,
      };

      return {
        todoList: [...state.todoList, item.uuid],
        items: { ...state.items, [item.uuid]: item },
      };
    }
    case "DELETE": {
      const newItems = { ...state.items };
      delete newItems[action.uuid];

      return {
        todoList: state.todoList.filter((u) => u !== action.uuid),
        items: newItems,
      };
    }
    case "CHECK": {
      let item = state.items[action.uuid];
      item = { ...item, checked: action.checked };

      return {
        todoList: state.todoList,
        items: {
          ...state.items,
          [action.uuid]: item,
        },
      };
    }
  }
}

const initialState: State = {
  todoList: [],
  items: {},
};

function TodoList({
  canCreate,
  canRead,
  canUpdate,
  canDelete,
}: {
  canCreate: boolean;
  canRead: boolean;
  canUpdate: boolean;
  canDelete: boolean;
}) {
  const [state, dispatch] = useReducer(reducer, initialState, () => {
    const stored = window.localStorage.getItem("state");
    if (!stored) {
      return initialState;
    }
    return JSON.parse(stored) as State;
  });

  useEffect(() => {
    window.localStorage.setItem("state", JSON.stringify(state));
  }, [state]);

  if (!canRead) {
    return <div>you are not allowed to read the todo list!</div>;
  }

  return (
    <div>
      {state.todoList.map((uuid) => {
        const item = state.items[uuid];
        return (
          <ItemRow
            key={item.uuid}
            item={item}
            onDelete={() => dispatch({ type: "DELETE", uuid: item.uuid })}
            onCheck={(checked) =>
              dispatch({ type: "CHECK", uuid: item.uuid, checked })
            }
            canUpdate={canUpdate}
            canDelete={canDelete}
          />
        );
      })}
      {canCreate ? (
        <EntryRow
          onSubmit={(contents) => dispatch({ type: "CREATE", contents })}
        />
      ) : null}
    </div>
  );
}

export default TodoList;
