import React, { useState } from "react";
import { PlusIcon } from "@heroicons/react/solid";

function EntryRow({ onSubmit }: { onSubmit: (contents: string) => void }) {
  const [contents, setContents] = useState("");

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    onSubmit(contents);
    setContents("");
  }

  return (
    <form className="flex flex-row items-center" onSubmit={handleSubmit}>
      <PlusIcon className="mr-2 w-4 h-4 text-gray-500" />
      <input
        type="text"
        className="focus:outline-none"
        placeholder="Add a todo"
        autoFocus
        value={contents}
        onChange={(e) => setContents(e.target.value)}
      />
    </form>
  );
}

export default EntryRow;
