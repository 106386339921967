import { useAuth0 } from "@auth0/auth0-react";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";

interface Token {
  permissions: string[];
}

export default function usePermissions(): string[] {
  const [permissions, setPermissions] = useState<string[]>([]);
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    async function decodeToken() {
      const token = jwt_decode(await getAccessTokenSilently()) as Token;
      setPermissions(token.permissions);
    }

    if (isAuthenticated) {
      decodeToken();
    }
  }, [user, isAuthenticated]);

  return permissions;
}
