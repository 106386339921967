import { useAuth0 } from "@auth0/auth0-react";

import { LoginButton, LogoutButton } from "./AuthButtons";
import TodoList from "./TodoList";
import Profile from "./Profile";
import { useEffect, useState } from "react";
import usePermissions from "./usePermissions";

function AuthInfo() {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const permissions = usePermissions();

  if (isLoading) {
    return null;
  }

  if (isAuthenticated && user) {
    return (
      <div className="border-gray-200 border-4 p-5 mb-5">
        <LogoutButton />
        <Profile user={user} />
        <p>You have these permissions:</p>
        <p className="font-mono">{JSON.stringify(permissions)}</p>
      </div>
    );
  }

  return <LoginButton />;
}

function App() {
  const permissions = usePermissions();

  return (
    <div className="container p-6 max-w-sm mx-auto">
      <h1 className="text-3xl font-bold mb-4">todo app</h1>
      <AuthInfo />
      <TodoList
        canCreate={permissions.includes("create:todo")}
        canRead={permissions.includes("read:todo")}
        canUpdate={permissions.includes("update:todo")}
        canDelete={permissions.includes("delete:todo")}
      />
    </div>
  );
}

export default App;
