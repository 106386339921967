import { useAuth0 } from "@auth0/auth0-react";

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <button
      onClick={() => loginWithRedirect()}
      className="w-full h-20 mb-4 rounded-xl text-3xl bg-blue-600 text-white focus:outline-none focus:ring-4"
    >
      Log In
    </button>
  );
};

export const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <button
      onClick={() => logout({ returnTo: window.location.href })}
      className="w-full h-20 mb-4 rounded-xl text-3xl bg-red-500 text-white focus:outline-none focus:ring-4 ring-red-300"
    >
      Log Out
    </button>
  );
};
