import { User } from "@auth0/auth0-react";

const Profile = ({ user }: { user: User }) => {
  return (
    <div className="flex flex-row">
      <img className="rounded-full mr-4" src={user.picture} alt={user.name} />
      <div className="flex flex-col items-start justify-center">
        <h2 className="text-lg font-bold">{user.name}</h2>
        <p>{user.email}</p>
      </div>
    </div>
  );
};

export default Profile;
